import {createSlice} from "@reduxjs/toolkit"
import { Services } from "../../types/services"
import { getToken } from "../../common/helpers"

interface ServiceState {
    servicelist: Services[] | null
    serviceadd: Services[] | null,
    servicedata: Services | null,
    serviceedit: Services [] | null
}

const initialState: ServiceState = {
    servicelist: null,
    serviceadd: null,
    servicedata: null,
    serviceedit: null,
}

export const serv = createSlice({
  name: "serv",
  initialState,
  reducers: {
    addService: (state, action) => {
        state.serviceadd = action.payload
    },
    saveService: (state, action) => {
        state.servicelist = action.payload
      },
    currentService: (state, action) => {
        state.servicedata = action.payload
      },
    changeService: (state, action) => {
        state.serviceedit = action.payload
    },
  },
})

export const { saveService } = serv.actions
export const fetchServiceList = () => {
    return (dispatch: any) => {
        return fetch(`/api/service/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,

            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((servicelist) => {
                                dispatch(saveService(servicelist));
                                return servicelist;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};
export const { currentService } = serv.actions
export const fetchCurrentService = (id: number) => {
    return (dispatch: any) => {
        return fetch(`/api/service/${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((servicelist) => {
                                dispatch(currentService(servicelist));
                                return servicelist;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};

export const { addService } = serv.actions
export const addServiceToList = (payload:Services) => {
    return (dispatch: any) => {
        return fetch(`/api/service/`, {
            method: "POST",
            body:
                JSON.stringify({
                    ...payload
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {

                    dispatch(addService(serv))
                } else if (res.status === 409) {
                    res.text().then(text => {
                        }
                    )
                }
                else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const { changeService } = serv.actions
export const editService = (payload: Services, id: number) => {
    return (dispatch: any) => {
        return fetch(`/api/service/${id}`, {
            method: "PATCH",
            body:
                JSON.stringify({
                   ...payload
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchCurrentService(id));
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const deleteService = (id: number) => {
    return () => {
        return fetch(`/api/service/${id}`, {
            method: "DELETE",
            body:
            JSON.stringify({
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};



export default serv.reducer
