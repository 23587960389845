import Typography from '@mui/material/Typography';
import { alpha, Autocomplete, Box, Button, createTheme, FormControl, Grid, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field, useFormik } from "formik";
import { ClassNames } from '@emotion/react';
import { object, string, number, date, boolean } from "yup";
import { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CalculateSharp, ExpandMore } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addComputerToGroup, editDevice, fetchCurrentDevice } from '../../redux/reducers/Computer';
import React from 'react';
import { itemSchema } from '../../Validation/ItemSchema';


const type = [
    { value: 'pc', label: 'Komputer' },
    { value: 'monitor',  label: 'Monitor'},
    { value: 'headphones',  label: 'Headset' },
    { value: 'keyboard',  label: 'Klawiatura' },
    { value: 'mouse',  label: 'Mysz'},
]

const owner = [
    {value: 'datarobot', label: 'datarobot'},
    {value: 'devopsbay', label: 'devopsbay'},
]
const operating_system = [
    { label: 'linux/windows'},
    { label: 'MacOS'},
]


function EditItemform() {
const navigate = useNavigate()
const {serialNumber} = useParams();
const dispatch = useAppDispatch();
const comp = useAppSelector(state => state.comp.devicedata);
// const navigate = useNavigate()

useEffect(() => {
    if (!comp) {
        dispatch(fetchCurrentDevice(String(serialNumber)))
    }

}, []);

const initialFormState = {
    producer: comp?.producer || "",
    model: comp?.model || "",
    serial_number: comp?.serial_number || "",
    type: comp?.type || "",
    processor: comp?.processor || "",
    gpu: comp?.gpu || "",
    ram: comp?.ram || "",
    year: comp?.year || "",
    value: comp?.value || "",
    owner: comp?.owner || "",
    operating_system: comp?.operating_system || "",
    description: comp?.description || "",
    producer_serial_number: comp?.producer_serial_number || "",
}

    return (
        <><Toolbar disableGutters sx={{
            paddingTop: 9,
            paddingBottom: 6,
            alignItems: 'flex-start'
        }}>

            <Typography variant='h1' sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' }
            }}>
                <div>Formularz sprzętu
                    <Typography variant='subtitle1'>Edycja / Dodawanie</Typography></div>
            </Typography>

        </Toolbar>
            <div style={{ width: "700px" }}>
                <Formik
                    initialValues={initialFormState}
                    validationSchema={itemSchema}
                    enableReinitialize
                    //onSubmit={(values) => dispatch(editDevice(values,String(serialNumber)))}
                    onSubmit={async(values) =>
                        {await dispatch(editDevice(values,String(serialNumber)))
                        navigate('/computers')}}
                   // onSubmit={(values) => console.log(values)}
                >
                    {({ handleChange, values, setFieldValue,handleSubmit, touched, errors, isValid, handleBlur }) => (<Form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid xs={6} item>
                                <TextField
                                    id="producer"
                                    name="producer"
                                    value={values.producer}
                                    onChange={handleChange}
                                    label="Producent"
                                    variant="outlined"
                                    fullWidth
                                    error={touched.producer && !!errors.producer}
                                    helperText={touched.producer ? errors.producer : ""}
                                    onBlur={handleBlur}
                                    />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="model"
                                    name="model"
                                    value={values.model}
                                    onChange={handleChange}
                                    label="Model"
                                    variant="outlined"
                                    fullWidth
                                    error={touched.model && !!errors.model}
                                    helperText={touched.model ? errors.model : ""}
                                    onBlur={handleBlur}
                                    />
                            </Grid>
                            <Grid xs={6} item>
                            <Autocomplete
                                disablePortal
                                id="type"
                                popupIcon={<ExpandMore />}
                                value={type.find(item => item.value === values.type)}
                                options={type}
                                onChange={(e, value) => setFieldValue("type", value?.value)}
                                renderInput={(params) =>
                                  <TextField
                                    {...params} label="Typ"
                                    error={touched.type && !!errors.type}
                                    helperText={touched.type ? errors.type : ""}
                                    onBlur={handleBlur}
                                  />}
                            />
                             </Grid>
                             <Grid xs={6} item>
                             <Autocomplete
                                disablePortal
                                id="owner"
                                popupIcon={<ExpandMore />}
                                value={owner.find(item => item.value === values.owner)}
                                options={owner}
                                onChange={(e, owner) => setFieldValue("owner", owner?.value)}
                                renderInput={(params) =>
                                    <TextField
                                    {...params} label="Właściciel"
                                    error={touched.owner && !!errors.owner}
                                    helperText={touched.owner ? errors.owner : ""}
                                    onBlur={handleBlur}
                                    />}
                                    />
                             </Grid>

                            <Grid xs={6} item>
                                <TextField
                                    id="producer_serial_number"
                                    name="producer_serial_number"
                                    value={values.producer_serial_number}
                                    onChange={handleChange}
                                    label="Numer seryjny producenta"
                                    variant="outlined"
                                    fullWidth
                                    error={touched.producer_serial_number && !!errors.producer_serial_number}
                                    helperText={touched.producer_serial_number ? errors.producer_serial_number : ""}
                                    onBlur={handleBlur}
                                    />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="value"
                                    name="value"
                                    value={values.value}
                                    onChange={handleChange}
                                    label="Wartość sprzętu"
                                    variant="outlined"
                                    fullWidth
                                    error={touched.value && !!errors.value}
                                    helperText={touched.value ? errors.value : ""}
                                    onBlur={handleBlur}
                                    />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="year"
                                    name="year"
                                    value={values.year}
                                    onChange={handleChange}
                                    label="Rok produkcji"
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="processor"
                                    name="processor"
                                    value={values.processor}
                                    onChange={handleChange}
                                    label="Procesor"
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="ram"
                                    name="ram"
                                    value={values.ram}
                                    onChange={handleChange}
                                    label="RAM"
                                    variant="outlined"
                                    fullWidth
                                    error={touched.ram && !!errors.ram}
                                    helperText={touched.ram ? errors.ram : ""}
                                    onBlur={handleBlur}
                                    />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="gpu"
                                    name="gpu"
                                    value={values.gpu}
                                    onChange={handleChange}
                                    label="Karta graficzna"
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid xs={6} item>
                             <Autocomplete
                                disablePortal
                                id="operating_system"
                                popupIcon={<ExpandMore />}
                                options={operating_system}
                                onChange={(e, value) => setFieldValue("operating_system", value?.label)}
                                renderInput={(params) => <TextField {...params} label="System operacyjny" />}
                                />
                             </Grid>
                            <Grid xs={6} item>
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="outlined" size="medium" color="secondary" sx={{ width: "150px" }} style={{ fontWeight: '400' }}>Akceptuj</Button>
                            </Grid>
                            <Box width="100%" />
                            <Grid item>
                                <Button variant="outlined" size="medium" color="info" sx={{ width: "150px" }} style={{ fontWeight: '400' }} onClick={() => navigate(-1)}>Wróć</Button>
                            </Grid>
                        </Grid>
                    </Form>)}
                </Formik>
            </div>

        </>

    );
}


export default EditItemform;
