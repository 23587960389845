import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/User'
import deviceReducer from './reducers/Computer'
import demandReducer from './reducers/Demand'
import serviceReducer from './reducers/Service'
import loginReducer from './reducers/Login'

export const store = configureStore({
  reducer: {user:userReducer, comp:deviceReducer, dem:demandReducer, serv:serviceReducer, login:loginReducer },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch