import {createSlice} from "@reduxjs/toolkit"
import { LoginType } from "../../types/login"
import { setToken } from "../../common/helpers"

interface LoginState {
    postlogin: LoginType[] | null,
}

const initialState: LoginState = {
    postlogin: null,
}

export const login = createSlice({
  name: "login",
  initialState,
  reducers: {
    sendLogin: (state, action) => {
        state.postlogin = action.payload
    },

  },
})


export const { sendLogin } = login.actions
export const postLogin = (username: string, password:string) => {
    const data = new FormData()
    data.append("username", username);
    data.append("password", password);
    return (dispatch: any) => {
        return fetch(`/api/login`, {
            method: "POST",
            body:
                data,
            headers: {
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                      .json()
                      .then((data) => {
                          setToken(data.access_token)
                          return data
                        }
                      )

                     } else if (res.status === 409) {
                    res.text().then(text => {

                        }
                    )
                }
                else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};


export default login.reducer
