import { Navigate, Route, Routes } from "react-router-dom";
import Users from '../pages/Users/Users';
import Computers from '../pages/Items/Computers';
import Accessories from '../pages/Items/Accessories';
import Applayout from './Applayout';
import Service from '../pages/Service/Service';
import NotFoundPage from '../pages/404';
import Singleuser from "../pages/Users/Singleuser";
import SingleComputer from "../pages/Items/SingleComputer";
import Userform from "../pages/Users/Userform";
import Itemform from "../pages/Items/Itemform";
import SerialNumberList from "../pages/SerialNumberList";
import EditUserForm from "../pages/Users/EditUserForm";
import Demand from "../pages/Demand/Demand";
import DemandForm from "../pages/Demand/DemandForm";
import EditItemform from "../pages/Items/EditItemForm";
import Login from "../pages/Login";
import PrivateRoutes from "./PrivateRoute";
import SingleAccessory from "../pages/Items/SingleAccessory";
import EditDemandForm from "../pages/Demand/EditDemandForm";
import SingleDemand from "../pages/Demand/SingleDemand";
import ServiceForm from "../pages/Service/ServiceForm";
import EditServiceForm from "../pages/Service/EditServiceForm";

const Routing = () => {
  return (
    
        <Routes>
         <Route path="login" element={<Login/>} />
         <Route element={<Applayout />}>
          <Route element={<PrivateRoutes/>}>
         <Route path="/" element={ <Navigate to="/login"/> }/>
         <Route path="users" element={<Users/>} />
         <Route path="computers" element={<Computers/>} />
         <Route path="accessories" element={<Accessories/>} />
         <Route path="service" element={<Service/>} />
         <Route path="/serviceform" element={<ServiceForm/>} />
         <Route path="/editserviceform/:id" element={<EditServiceForm/>} />
         <Route path="demand" element={<Demand/>} />
         <Route path="/demand/:id" element={<SingleDemand/>} />
         <Route path="/demandform" element={<DemandForm/>} />
         <Route path="/editdemandform/:id" element={<EditDemandForm/>} />
         <Route path="serialnumber" element={<SerialNumberList/>} />
         <Route path="/user/:id" element={<Singleuser/>} />
         <Route path="/userform" element={<Userform/>} />
         <Route path="/editform/:id" element={<EditUserForm/>} />
         <Route path="/itemform" element={<Itemform/>} />
         <Route path="/edititemform/:serialNumber" element={<EditItemform/>} />
         <Route path="/computer/:serialNumber" element={<SingleComputer/>} />
         <Route path="/accessory/:serialNumber" element={<SingleAccessory/>} />
         </Route>
         </Route>
         <Route path="*" element={<NotFoundPage/>} />
       </Routes>  

  );
}

export default Routing;