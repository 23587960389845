import * as yup from 'yup';
const requiredErrorMessage = "Wymagane";
const requiredErrorType = "Podaj liczbę";
export const itemSchema = yup.object().shape({
  model: yup.string().required(requiredErrorMessage),
  producer: yup.string().required(requiredErrorMessage),
  type: yup.string().required(requiredErrorMessage),
  owner: yup.string().required(requiredErrorMessage),
  producer_serial_number: yup.string().required(requiredErrorMessage),
  value: yup.number().required(requiredErrorMessage).positive().integer().typeError(requiredErrorType),
  year: yup.number().positive().integer().typeError(requiredErrorType),
  ram: yup.number().positive().nullable(true).integer().typeError(requiredErrorType).when("type",{
    is: "pc",
    then: yup.number().positive().nullable(false).integer().typeError(requiredErrorType)
  }),
});
