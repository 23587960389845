import {createSlice} from "@reduxjs/toolkit"
import { UserType } from "../../types/interfaces"
import { getToken } from "../../common/helpers"

interface UserState {
    list: UserType[] | null,
    userdata: UserType | null,
    useradd: UserType[] | null,
    edituser: UserType[] | null,
}

const initialState: UserState = {
    list: null,
    userdata: null,
    useradd: null,
    edituser: null,
}

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUsers: (state, action) => {
      state.list = action.payload
    },
    currentUser: (state, action) => {
        state.userdata = action.payload
    },
    addUser: (state, action) => {
        state.useradd = action.payload
    },
    changeUser: (state, action) => {
        state.edituser = action.payload
    },

  },
})

export const { saveUsers } = user.actions
export const fetchUsers = () => {
    return (dispatch: any) => {
        return fetch(`/api/workers/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((users) => {
                                dispatch(saveUsers(users));
                                return users;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};

export const { addUser } = user.actions
export const addUserToGroup = (name: string, second_name:string, workplace:string) => {
    return (dispatch: any) => {
        return fetch(`/api/workers/`, {
            method: "POST",
            body:
                JSON.stringify({
                    name,
                    second_name,
                    workplace
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {

                    dispatch(addUser(user))
                } else if (res.status === 409) {
                    res.text().then(text => {
                            if (text.includes("User is already in group")) {
                            }
                        }
                    )
                }
                else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};
export const { changeUser } = user.actions
export const editUser = (payload: UserType, id:number) => {
    return (dispatch: any) => {
        return fetch(`/api/workers/${id}`, {
            method: "PATCH",
            body:
                JSON.stringify({
                   ...payload
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchUser(id));
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const deleteUser = (id: number) => {
    return () => {
        return fetch(`/api/workers/${id}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};


export const { currentUser } = user.actions
export const fetchUser = (id: number) => {
    return (dispatch: any) => {
        return fetch(`/api/workers/${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((users) => {
                                dispatch(currentUser(users));
                                return users;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};

export default user.reducer





