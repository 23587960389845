
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Box, Button, Chip } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import StyledSearch from '../../components/Searchbar';
import { useEffect } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import { Computer } from '../../types/deviceinterfaces';
import { fetchAccessories } from '../../redux/reducers/Computer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchUsers } from '../../redux/reducers/User';


      function Accessories() {
        const dispatch = useAppDispatch()
        const AccessoryList = useAppSelector(state => state.comp.devices);
        const usersList = useAppSelector(state => state.user.list);

        useEffect(() => {
          dispatch(fetchAccessories())
          dispatch(fetchUsers())
        }, []);

        const getUserName = (id: string | undefined) => {
          if (usersList && (usersList.length > 0)) {
            const user = usersList.find(user => user.id === id)
            return user?.name + ' ' + user?.second_name
          } else
            return '-'
        }

        return (
        <><>
            <Toolbar disableGutters>
              <Typography variant='h1' sx={{
                paddingTop: 9,
                paddingBottom: 9,
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' } }}>
                    Lista akcesoriów
                    </Typography>
                    {/* <StyledSearch /> */}
            </Toolbar>

            <TableContainer sx={{ paddingRight: '5%' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Producent / Model</TableCell>
                    <TableCell align="left">Nr seryjne</TableCell>
                    <TableCell align="left">Typ sprzętu</TableCell>
                    <TableCell align="left">Wartość</TableCell>
                    <TableCell align="left">Przypisanie</TableCell>
                    <TableCell align="left">Szczegóły</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AccessoryList && AccessoryList.map((row:Computer) => (
                    <TableRow
                      key={row.model}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& .MuiTableCell-root': { color: '#3B3E45', fontWeight: '400' }, }}>
                      <TableCell align="left">{row.producer}<br/>{row.model}</TableCell>
                      <TableCell align="left">
                        <div><strong>{row.producer_serial_number}</strong></div>
                        <small>{row.serial_number}</small>
                      </TableCell>
                      <TableCell align="left">{row.type}</TableCell>
                      <TableCell align="left">{row.value}</TableCell>
                      <TableCell align="left">{row.id_worker ? <Chip label={getUserName(row.id_worker)} color="secondary" /> : '-'}</TableCell>
                      <TableCell align="left"><Button variant="outlined" size="small" color="secondary" component={RouterLink} to={`/accessory/${row.serial_number}`}>Szczegóły</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Divider />
            </TableContainer>
            </>
              <Box
              m={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ paddingRight: '5%' }}>
              <Button variant="text" color="secondary" startIcon={<AddIcon />} sx={{ height: 40 }} component={RouterLink} to={`/itemform`}>Dodaj sprzęt</Button>
            </Box>
            </>
        );
      }

export default Accessories;
