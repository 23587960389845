import Typography from '@mui/material/Typography';
import { alpha, Box, Button, createTheme, Grid, makeStyles, Stack, styled, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import { ClassNames } from '@emotion/react';
import { object, string, number, date, boolean } from "yup";
import { useEffect, useState } from 'react';
import { addUserToGroup, editUser, fetchUser } from '../../redux/reducers/User';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';




function EditUserForm() {

    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.user.userdata);

   const {id} = useParams()


    useEffect(() => {
        if (!user) {
            dispatch(fetchUser(Number(id)))
        }
        
    }, []);

   const initialFormState = {
    name: user?.name || "",
    second_name: user?.second_name  || "",
    workplace: user?.workplace  || ""
    }
    const navigate = useNavigate()
    return (
        <><Toolbar disableGutters sx={{
            paddingTop: 9,
            paddingBottom: 6,
            alignItems: 'flex-start'
        }}>

            <Typography variant='h1' sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' }
            }}>
                <div>Formularz pracownika
                    <Typography variant='subtitle1'>Edycja / Dodawanie</Typography></div>
            </Typography>

        </Toolbar>
            <div style={{ width: "600px" }}>
                <Formik
                    initialValues={initialFormState}
                    enableReinitialize
                    //onSubmit={(values) => dispatch(editUser(values,Number(id)))}
                    onSubmit={async(values) => 
                        {await dispatch(editUser(values,Number(id)))
                        navigate('/users')}}
                >
                    {({values, handleSubmit, handleChange}) => (<Form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid xs={6} item>
                                <TextField
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    label="Imię"
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="second_name"
                                    name="second_name"
                                    value={values.second_name}
                                    onChange={handleChange}
                                    label="Nazwisko"
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    id="workplace"
                                    name="workplace"
                                    value={values.workplace}
                                    onChange={handleChange}
                                    label="Stanowisko"
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="outlined" size="medium" color="secondary" sx={{ width: "150px" }} style={{ fontWeight: '400' }}>Akceptuj</Button>
                            </Grid>
                            <Box width="100%" />
                            <Grid item>
                                <Button variant="outlined" size="medium" color="info" sx={{ width: "150px" }} style={{ fontWeight: '400' }} component={RouterLink} to="/users">Wróć</Button>
                            </Grid>
                        </Grid>
                    </Form>)}
                </Formik>
            </div>

        </>

    );
}


export default EditUserForm;