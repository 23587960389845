import Typography from '@mui/material/Typography';
import {Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, } from "formik";
import { useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchUsers } from '../../redux/reducers/User';
import { fetchComputers } from '../../redux/reducers/Computer';
import { addServiceToList } from '../../redux/reducers/Service';
import { ServiceSchema } from '../../Validation/ServiceSchema';


const type = [
    { value: 'pc', label: 'Komputer' },
    { value: 'monitor',  label: 'Monitor'},
    { value: 'headphones',  label: 'Headset' },
    { value: 'keyboard',  label: 'Klawiatura' },
    { value: 'mouse',  label: 'Mysz'},
]
const status = [
    { value: 'reported', label: 'Zgłoszone' },
    { value: 'inService',  label: 'W trakcie serwisu'},
]

function ServiceForm() {
const navigate = useNavigate()
const dispatch = useAppDispatch();
const DeviceList = useAppSelector(state => state.comp.computers);
useEffect(() => {
    if(!DeviceList?.length){
        dispatch(fetchComputers())
    }
    }, [])

   
const mappedDeviceList = DeviceList ? DeviceList.map((comp) => { return { label: comp.serial_number + ' ' + comp.model, value: comp.serial_number } } ) : []



const initialFormState = {
    serial_number: "",
    type: "",
    status:"",
    problem: "",
    note: "",
    id: "",
}

    return (
        <><Toolbar disableGutters sx={{
            paddingTop: 9,
            paddingBottom: 6,
            alignItems: 'flex-start'
        }}>

            <Typography variant='h1' sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' }
            }}>
                <div>Zgłoszenie serwisu
                    {/* <Typography variant='subtitle1'>Edycja / Dodawanie</Typography> */}
                    </div>
            </Typography>

        </Toolbar>
            <div style={{ width: "700px" }}>
                <Formik
                    initialValues={initialFormState}
                    validationSchema={ServiceSchema}
                    onSubmit={async(values) => 
                        {await dispatch(addServiceToList(values))
                        navigate('/service')}}
                >
                    {({ handleChange, values, setFieldValue,handleSubmit, touched, errors, handleBlur }) => (<Form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justifyContent="center">
                            
                        <Grid xs={12} item>
                        <Autocomplete
                                disablePortal
                                id="serial_number"
                                popupIcon={<ExpandMore />}
                                options={mappedDeviceList}
                                onChange={(e, value) => setFieldValue("serial_number", value?.value)}
                                renderInput={(params) => 
                                <TextField {...params} label="Sprzęt" 
                                error={touched.serial_number && !!errors.serial_number}
                                helperText={touched.serial_number ? errors.serial_number : ""}
                                onBlur={handleBlur}
                                />}
                                /> 
                            </Grid> 
                            <Grid xs={6} item>
                            <Autocomplete
                                disablePortal
                                id="type"
                                popupIcon={<ExpandMore />}
                                options={type}
                                onChange={(e, value) => setFieldValue("type", value?.value)}
                                renderInput={(params) => 
                                <TextField {...params} label="Typ" 
                                error={touched.type && !!errors.type}
                                helperText={touched.type ? errors.type : ""}
                                onBlur={handleBlur}
                                />}
                                /> 
                             </Grid>
                             <Grid xs={6} item>
                            <Autocomplete
                                disablePortal
                                id="status"
                                popupIcon={<ExpandMore />}
                                options={status}
                                onChange={(e, value) => setFieldValue("status", value?.value)}
                                renderInput={(params) => 
                                <TextField {...params} label="Status" 
                                error={touched.status && !!errors.status}
                                helperText={touched.status ? errors.status : ""}
                                onBlur={handleBlur}
                                />}
                                /> 
                             </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    id="problem"
                                    name="problem"
                                    value={values.problem}
                                    onChange={handleChange}
                                    label="Problem"
                                    variant="outlined"
                                    fullWidth 
                                    required 
                                    error={touched.problem && !!errors.problem}
                                    helperText={touched.problem ? errors.problem : ""}
                                    onBlur={handleBlur}
                                    />
                            </Grid>

                            <Grid xs={12} item>
                                <TextField
                                    id="note"
                                    name="note"
                                    value={values.note}
                                    onChange={handleChange}
                                    label="Komentarz"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth />
                            </Grid>


                            <Grid item>
                                <Button type="submit" variant="outlined" size="medium" color="secondary" sx={{ width: "150px" }} style={{ fontWeight: '400' }}>Akceptuj</Button>
                            </Grid>
                            <Box width="100%" />
                            <Grid item>
                                <Button variant="outlined" size="medium" color="info" sx={{ width: "150px" }} style={{ fontWeight: '400' }} component={RouterLink} to="/service">Wróć</Button>
                            </Grid>
                        </Grid>
                    </Form>)}
                </Formik>
            </div>

        </>

    );
}


export default ServiceForm;