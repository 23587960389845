import { InputBase } from '@mui/material';
import { ThemeProvider, createTheme, styled, alpha } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23) !important',
          },
          "&.Mui-focused fieldset": {
            borderColor: '#4C7DE7 !important', 
          },

        },
      }
    },
    
  },
  
  palette: {
    primary: {
      main: '#4D515A'
    },
    secondary: {
      main: '#4C7DE7'
    },
    success: {
      main: '#2B9629'
    },
    error: {
      main: '#CF3127'
    },
    info: {
      main: '#525865'
    }
  },

  typography: {
    allVariants: {
      fontFamily: 'inter',
      fontSize: 15,
      color: '#4D515A',
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontSize: 14,
    },
    h1: {
      fontSize: 29,
      fontWeight: 600,
      color: '#4C7DE7',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
    },
  },


})



export default theme;


