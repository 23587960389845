import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Box, Button,  styled,  } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect, useState } from 'react';
import { fetchCurrentDevice } from '../../redux/reducers/Computer';
import { deleteDemand, fetchCurrentDemand } from '../../redux/reducers/Demand';




      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          width: 230 ,
        },
        [`&.${tableCellClasses.body}`]: {
          fontWeight: 400,
        },
      }));

      function SingleDemand() {
        const {id} = useParams()
        const navigate = useNavigate()
        const dispatch = useAppDispatch()
        const dem = useAppSelector(state => state.dem.demanddata);
       

          useEffect(() => {
            dispatch(fetchCurrentDemand(Number(id)))
          }, []);

          const handleDelete = async (demandId: number) => {
             if(!window.confirm("Czy napewno chcesz usunąć zgłoszenie?")) {
               return false
             }
            await dispatch(deleteDemand(demandId))
            navigate("/demand")
          }


        return (
        <><>
            <Toolbar disableGutters sx={{
                paddingTop: 9, 
                paddingBottom: 6, 
                alignItems: 'flex-start'
            }}>

              <Typography variant='h1' sx={{ 
                flexGrow: 1, 
                display: { xs: 'none', sm: 'block' } }}>
                  {dem &&
                  <div>{dem.name}
                    <Typography variant='subtitle1'>Zapotrzebowanie-szczegóły</Typography></div>
                    }
                    </Typography>
                  

                    <Box pr={7}>
                    <Button variant="outlined" size="medium" color="info" sx={{mr:1, px: 4}} style={{ fontWeight: '400' }} component={RouterLink} to="/demand">Wróć</Button>
                   {dem &&<Button variant="outlined" size="medium" color="secondary" sx={{mr:1, px: 3}} style={{ fontWeight: '400' }} component={RouterLink} to={`/editdemandform/${dem.id}`}>Edycja</Button>}
                                         
                        <Button 
                        type="submit" 
                        onClick={() => handleDelete(Number(id))} 
                        variant="outlined" 
                        size="medium"
                        color="error" 
                        sx={{px: 4}} 
                        style={{ fontWeight: '400' }}>
                        Usuń
                        </Button>
                    </Box>
            </Toolbar>
            {dem && 
            <Table sx={{ maxWidth: 700 }}>
              <TableRow>
                <StyledTableCell variant="head">Nazwa sprzętu</StyledTableCell>
                <StyledTableCell variant="body">{dem.name}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">Typ</StyledTableCell>
                <StyledTableCell variant="body">{dem.type}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">Status</StyledTableCell>
                <StyledTableCell variant="body">{dem.status}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">Wartość</StyledTableCell>
                <StyledTableCell variant="body">{dem.value}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">Notatki</StyledTableCell>
                <StyledTableCell variant="body"><div>{dem.description}</div></StyledTableCell>
              </TableRow>
            </Table>
      }

            </>
            </>            
        );
      }


export default SingleDemand;