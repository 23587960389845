import Typography from '@mui/material/Typography';
import { alpha, Box, Button, createTheme, Grid, makeStyles, Stack, styled, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import { ClassNames } from '@emotion/react';
import { object, string, number, date, boolean } from "yup";
import { useState } from 'react';
import { addUserToGroup } from '../../redux/reducers/User';
import { useAppDispatch } from '../../redux/hooks';
import * as yup from 'yup';

const initialFormState = {
    name: "",
    second_name: "",
    workplace: "",
}

const requiredErrorMessage = "Wymagane";
export const UserSchema = yup.object().shape({
    name: yup.string().required(requiredErrorMessage),
    second_name: yup.string().required(requiredErrorMessage),
    workplace: yup.string().required(requiredErrorMessage),
});

function Userform() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    return (
        <><Toolbar disableGutters sx={{
            paddingTop: 9,
            paddingBottom: 6,
            alignItems: 'flex-start'
        }}>

            <Typography variant='h1' sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' }
            }}>
                <div>Formularz pracownika
                    <Typography variant='subtitle1'>Edycja / Dodawanie</Typography></div>
            </Typography>

        </Toolbar>
            <div style={{ width: "600px" }}>
                <Formik
                  initialValues={initialFormState}
                  validationSchema={UserSchema}
                  onSubmit={async(values) =>
                  {await dispatch(addUserToGroup(values.name,values.second_name,values.workplace))
                      navigate('/users')}}
                >
                    {({values, handleSubmit, errors, touched, handleBlur, handleChange}) => (<Form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid xs={6} item>
                                <TextField
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    label="Imię"
                                    variant="outlined"
                                    error={touched.name && !!errors.name}
                                    helperText={touched.name ? errors.name : ""}
                                    onBlur={handleBlur}
                                    fullWidth />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="second_name"
                                    name="second_name"
                                    value={values.second_name}
                                    onChange={handleChange}
                                    label="Nazwisko"
                                    variant="outlined"
                                    error={touched.second_name && !!errors.second_name}
                                    helperText={touched.second_name ? errors.second_name : ""}
                                    onBlur={handleBlur}
                                    fullWidth />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    id="workplace"
                                    name="workplace"
                                    value={values.workplace}
                                    onChange={handleChange}
                                    label="Stanowisko"
                                    variant="outlined"
                                    error={touched.workplace && !!errors.workplace}
                                    helperText={touched.workplace ? errors.workplace : ""}
                                    onBlur={handleBlur}
                                    fullWidth />
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="outlined" size="medium" color="secondary" sx={{ width: "150px" }} style={{ fontWeight: '400' }}>Akceptuj</Button>
                            </Grid>
                            <Box width="100%" />
                            <Grid item>
                                <Button variant="outlined" size="medium" color="info" sx={{ width: "150px" }} style={{ fontWeight: '400' }} component={RouterLink} to="/users">Wróć</Button>
                            </Grid>
                        </Grid>
                    </Form>)}
                </Formik>
            </div>

        </>

    );
}


export default Userform;
