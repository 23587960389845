import Typography from '@mui/material/Typography';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, } from "formik";
import { addUserToGroup, fetchUsers } from '../../redux/reducers/User';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect } from 'react';
import { assignDevice, fetchComputers, fetchDevices, fetchUserHistory } from '../../redux/reducers/Computer';
import { ExpandMore, WorkspacePremiumRounded } from '@mui/icons-material';
import { DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';




function AssignWorker({workerId}:{workerId:string|undefined}) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const DeviceList = useAppSelector(state => state.comp.alldevices);
    useEffect(() => {
        if(!DeviceList?.length){
            dispatch(fetchDevices())
        }
        }, [])

   const initialFormState = {
    start_date: "",
    device_id: "",
    id_worker: workerId,
    }
    const mappedDeviceList = DeviceList ? DeviceList.map((comp) => { return { label: comp.producer_serial_number + ' | ' + comp.model, value: comp.serial_number } } ) : []


    return (
        <><Toolbar disableGutters sx={{
            paddingTop: 9,
            paddingBottom: 6,
            alignItems: 'flex-start'
        }}>

            {/* <Typography variant='h1' sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' }
            }}>
                <div>Dodawanie sprzętu
                    <Typography variant='subtitle1'>Dodawanie sprzętu do pracownika</Typography></div>
            </Typography> */}

        </Toolbar>
        <div style={{ width: "500px" }}>
        <Formik
          initialValues={initialFormState}
           onSubmit={async(values) => {
             await dispatch(assignDevice(values,values.device_id))
             dispatch(fetchUserHistory(Number(workerId)))
          }}
        >
          {({ handleChange, values, setFieldValue, handleSubmit, touched, errors, handleBlur }) => (<Form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">

              <Grid xs={6} item>
                <Autocomplete
                  disablePortal
                  id="device_id"
                  popupIcon={<ExpandMore />}
                  options={mappedDeviceList}
                  onChange={(e, value) => setFieldValue("device_id", value?.value)}
                  renderInput={(params) => <TextField {...params} required label="Sprzęt" />}
                />
              </Grid>
              <Grid xs={6} item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Data przypisania"
                    inputFormat="yyyy-MM-dd"
                    value={values.start_date}
                    onChange={(newValue) => {
                      setFieldValue("start_date", newValue);
                    }}
                    renderInput={(params) =>
                    <TextField {...params}
                    required
                    error={touched.start_date && !!errors.start_date}
                    helperText={touched.start_date ? errors.start_date : ""}
                    onBlur={handleBlur}
                    />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="outlined"
                  size="medium"
                  color="secondary"
                  sx={{ width: "150px" }}
                  style={{ fontWeight: '400' }}
                >
                  Akceptuj</Button>
              </Grid>
            </Grid>
          </Form>)}
        </Formik>
      </div>
        </>

    );
}


export default AssignWorker;
