import './App.css';
import Routing from './components/Routing';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/Styles';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isTokenValid } from './common/helpers';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isTokenValid()) {
      if(location.pathname !== '/login') {
        navigate('/login', {replace: true})
      }
    }
    return
  }, [location]);


  return (
    <ThemeProvider theme={theme}>
      <Routing />
      <Box height="100px" />
    </ThemeProvider>

  );
}

export default App;
