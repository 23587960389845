import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect, useState } from 'react';
import { assignDevice, deleteDevice, fetchCurrentDevice, fetchDeviceHistory } from '../../redux/reducers/Computer';
import React from 'react';
import { fetchUsers } from '../../redux/reducers/User';
import { Form, Formik } from 'formik';
import { ExpandMore } from '@mui/icons-material';
import { itemSchema } from '../../Validation/ItemSchema';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    width: 230,
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 400,
  },
}));

function SingleDevice() {
  const { serialNumber } = useParams()
  const [deletedNote, setdeletedNote] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const comp = useAppSelector(state => state.comp.devicedata);
  const history = useAppSelector(state => state.comp.devicehistory);

  useEffect(() => {
    dispatch(fetchCurrentDevice(String(serialNumber)))
    dispatch(fetchDeviceHistory(String(serialNumber)))
  }, []);

  const handleDelete = async (deletedNote: string, deviceId: string) => {
    // if(!window.confirm("Czy napewno chcesz usunąć sprzęt?")) {
    //   return false
    // }
    await dispatch(deleteDevice(deletedNote, deviceId))
    navigate(-1)
  }

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const UsersList = useAppSelector(state => state.user.list);
  useEffect(() => {
    if (!UsersList?.length) {
      dispatch(fetchUsers())
    }
  }, [])
  const mappedUsersList = UsersList ? UsersList.map((user) => { return { label: user.name + ' ' + user.second_name, value: user.id } }) : []

  const initialFormState = {
    id_worker: "",
    start_date: "",
  }

  const getUserNameById = (id: number) => {
    const userdata = UsersList?.find(user => user.id === id);
    return userdata?.name + ' ' + userdata?.second_name
  }

  return (
    <><>
      <Toolbar disableGutters sx={{
        paddingTop: 9,
        paddingBottom: 6,
        alignItems: 'flex-start'
      }}>

        <Typography variant='h1' sx={{
          flexGrow: 1,
          display: { xs: 'none', sm: 'block' }
        }}>
          {comp &&
            <div>{comp.model}<a> </a>{comp.year}
              <Typography variant='subtitle1'>Komputer-szczegóły</Typography></div>
          }
        </Typography>


        <Box pr={7}>
          <Button variant="outlined" size="medium" color="info" sx={{ mr: 1, px: 4 }} style={{ fontWeight: '400' }} component={RouterLink} to="/computers">Wróć</Button>
          {comp && <Button variant="outlined" size="medium" color="secondary" sx={{ mr: 1, px: 3 }} style={{ fontWeight: '400' }} component={RouterLink} to={`/edititemform/${comp.serial_number}`}>Edycja</Button>}

          <Button variant="outlined" size="medium" color="error" sx={{ px: 4 }} style={{ fontWeight: '400' }} onClick={handleClickOpen}>
            Usuń
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Powód usunięcia sprzętu:</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="deleted_note"
                label="Powód usunięcia"
                type="deleted_note"
                fullWidth
                variant="standard"
                value={deletedNote}
                onChange={(e) => setdeletedNote(e.currentTarget.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Anuluj</Button>
              <Button type="submit" onClick={() => handleDelete(deletedNote, String(serialNumber))}>Usuń</Button>
            </DialogActions>
          </Dialog>

        </Box>
      </Toolbar>



      {comp &&
        <Table sx={{ maxWidth: 600 }}>
          <TableRow>
            <StyledTableCell variant="head">Producent</StyledTableCell>
            <StyledTableCell variant="body">{comp.producer}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Model</StyledTableCell>
            <StyledTableCell variant="body">{comp.model}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Numer seryjny</StyledTableCell>
            <StyledTableCell variant="body">{comp.serial_number}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Numer seryjny producenta</StyledTableCell>
            <StyledTableCell variant="body">{comp.producer_serial_number}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Procesor</StyledTableCell>
            <StyledTableCell variant="body">{comp.processor}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Karta graficzna</StyledTableCell>
            <StyledTableCell variant="body">{comp.gpu}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">RAM</StyledTableCell>
            <StyledTableCell variant="body">{comp.ram}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">System operacyjny</StyledTableCell>
            <StyledTableCell variant="body">{comp.operating_system}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Rok produkcji</StyledTableCell>
            <StyledTableCell variant="body">{comp.year}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Wartość</StyledTableCell>
            <StyledTableCell variant="body">{comp.value}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Właściciel</StyledTableCell>
            <StyledTableCell variant="body">{comp.owner}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell variant="head">Notatki</StyledTableCell>
            <StyledTableCell variant="body">{comp.description}</StyledTableCell>
          </TableRow>
        </Table>
      }
      <Typography variant='subtitle1' sx={{ pt: '55px', pb: '25px' }}>Przypisz sprzęt do użytkownika:</Typography>

      <div style={{ width: "500px" }}>
        <Formik
          initialValues={initialFormState}
           //validationSchema={itemSchema}
           onSubmit={async(values) =>
           {await dispatch(assignDevice(values,String(serialNumber)))
            window.location.reload();
          }}
          // onSubmit={(values) => dispatch(assignDevice(values, String(serialNumber)))}

        >
          {({ handleChange, values, setFieldValue, handleSubmit, touched, errors, handleBlur }) => (<Form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">

              <Grid xs={6} item>
                <Autocomplete
                  disablePortal
                  id="id_worker"
                  popupIcon={<ExpandMore />}
                  options={mappedUsersList}
                  onChange={(e, value) => setFieldValue("id_worker", value?.value)}
                  renderInput={(params) => <TextField {...params} required label="Pracownik" />}
                />
              </Grid>

              <Grid xs={6} item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Data przypisania"
                    inputFormat="yyyy-MM-dd"
                    value={values.start_date}
                    onChange={(newValue) => {
                      setFieldValue("start_date", newValue);
                    }}
                    renderInput={(params) =>
                    <TextField {...params}
                    required
                    error={touched.start_date && !!errors.start_date}
                    helperText={touched.start_date ? errors.start_date : ""}
                    onBlur={handleBlur}
                    />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="outlined"
                  size="medium"
                  color="secondary"
                  sx={{ width: "150px" }}
                  style={{ fontWeight: '400' }}
                >
                  Akceptuj</Button>
              </Grid>
            </Grid>
          </Form>)}
        </Formik>
      </div>

      <Typography variant='subtitle1' sx={{ pt: '55px', pb: '25px' }}>Użytkownik</Typography>

      <TableContainer sx={{ paddingRight: '5%' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Imię i nazwisko</TableCell>
              <TableCell align="left">Data przekazania</TableCell>
              <TableCell align="left">Data oddania</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.map((row) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& .MuiTableCell-root': { color: '#3B3E45', fontWeight: '400' }, }}>
                <TableCell align="left">{getUserNameById(row.id_worker)}</TableCell>
                <TableCell align="left">{row.start_date}</TableCell>
                <TableCell align="left">{row.end_date ? row.end_date : "-"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider />
      </TableContainer>
    </>
    </>
  );
}


export default SingleDevice;
