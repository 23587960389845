import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import StyledSearch, * as Styled from '../../components/Searchbar';
import { Link as RouterLink } from 'react-router-dom';
import { UserType } from '../../types/interfaces';
import { useEffect } from 'react';
import { fetchUsers } from '../../redux/reducers/User';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';



function Users() {
const dispatch = useAppDispatch()
const UsersList = useAppSelector(state => state.user.list);
  useEffect(() => {
     dispatch(fetchUsers())
  }, []);

  return (
    <><>
      <Toolbar disableGutters>
        <Typography variant='h1' sx={{
          paddingTop: 9,
          paddingBottom: 9,
          flexGrow: 1,
          display: { xs: 'none', sm: 'block' }
        }}>
          Lista pracowników</Typography>
       {/* <StyledSearch /> */}
      </Toolbar>

      <TableContainer sx={{ paddingRight: '5%' }}>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Imię</TableCell>
              <TableCell align="left">Nazwisko</TableCell>
              <TableCell align="left">Stanowisko</TableCell>
              <TableCell align="left">Szczegóły</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {UsersList && UsersList.map((row: UserType) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& .MuiTableCell-root': { color: '#3B3E45', fontWeight: '400' }, }}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.second_name}</TableCell>
                <TableCell align="left">{row.workplace}</TableCell>
                <TableCell align="left"><Button variant="outlined" size="small" color="secondary" component={RouterLink} to={`/user/${row.id}`}>Szczegóły</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider />
      </TableContainer>
    </>
      <Box
        m={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ paddingRight: '5%' }} >
        <Button variant="text" color="success" startIcon={<AddIcon />} sx={{ height: 40 }} component={RouterLink} to={`/userform`}>Dodaj pracownika</Button>
      </Box>
    </>
  );
}

export default Users;