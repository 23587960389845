import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect } from 'react';
import { deleteUser, fetchUser } from '../../redux/reducers/User';
import { fetchComputers, fetchDeviceHistory, fetchDevices, fetchUserHistory } from '../../redux/reducers/Computer';
import AssignWorker from './AssignWorker';


      function Computers() {
        const navigate = useNavigate()
        const dispatch = useAppDispatch()
        const UsersList = useAppSelector(state => state.user.list);
          useEffect(() => {
            dispatch(fetchUserHistory(Number(id)))
          }, []);

          const DeviceList = useAppSelector(state => state.comp.alldevices);
          useEffect(() => {
              if(!DeviceList?.length){
                  dispatch(fetchDevices())
              }
              }, [])
        
        const getDeviceNameById = (serial_number: string) => {
          const userdata = DeviceList?.find(user => user.serial_number === serial_number);
          return userdata?.model + ' ' + userdata?.producer
        }
        const getDeviceTypeById = (serial_number: string) => {
          const userdata = DeviceList?.find(user => user.serial_number === serial_number);
          return userdata?.serial_number + ' ' + userdata?.type
        }

        const {id} = useParams()
        const user = UsersList?.find(user=>user.id===Number(id))
        const history = useAppSelector(state => state.comp.userhistory);

        const handleDelete = async (userId: number) => {
          if(!window.confirm("Czy napewno chcesz usunąć użytkownika?")) {
            return false
          }
          await dispatch(deleteUser(userId))
          navigate("/users")
        }

        return (
        <><>
            <Toolbar disableGutters sx={{
                paddingTop: 9, 
                paddingBottom: 9, 
                alignItems: 'flex-start'
            }}>

          
              <Typography variant='h1' sx={{ 
                 flexGrow: 1, 
                display: { xs: 'none', sm: 'block' } }}>
                  {user &&
                  <div>{user.name}<a> </a>{user.second_name}
                    <Typography variant='subtitle1'>{user.workplace}</Typography></div>
                  }
                    </Typography>
                    
                    <Box pr={7}>{user &&
                    <Button variant="outlined" size="medium" color="success" sx={{mr:1, px: 3}} style={{ fontWeight: '400' }} component={RouterLink} to={`/editform/${user.id}`}>Edycja</Button>}
                    <Button type="submit" 
                     onClick={() => handleDelete(Number(id))}
                    variant="outlined" size="medium" color="error" sx={{px: 3}} style={{ fontWeight: '400' }}>Usuń</Button>
                    </Box>
            </Toolbar>

            <TableContainer sx={{ paddingRight: '5%' }}>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Nazwa sprzętu</TableCell>
                    <TableCell align="left">Numer seryjny / Typ</TableCell>
                    <TableCell align="left">Data przekazania</TableCell>
                    <TableCell align="left">Termin zwrotu</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {history?.map((row) => (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& .MuiTableCell-root': { color: '#3B3E45', fontWeight: '400' }, }}>
                      <TableCell align="left">{getDeviceNameById(row.serial_number)}</TableCell>
                      <TableCell align="left">{getDeviceTypeById(row.serial_number)}</TableCell>
                      <TableCell align="left">{row.start_date}</TableCell>
                      <TableCell align="left">{row.end_date ? row.end_date : "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Divider />
            </TableContainer>
            </>
              <Box
              m={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ paddingRight: '5%' }}>
             {/* <Button variant="text" color="secondary" startIcon={<AddIcon />} sx={{ height: 40 }}>Dodaj sprzęt</Button> */}
             <AssignWorker workerId={id}/>
            </Box>
            </>            
        );
      }


export default Computers;