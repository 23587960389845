import { Outlet, Navigate } from 'react-router-dom'
import { getToken } from '../common/helpers'

const useAuth=()=>{
    const user=localStorage.getItem("gear-token")
    if(user){
      return true
    } else {
      return false
    }
  }

const PrivateRoutes = () => {
    const auth = useAuth()
   // let auth = {'token':true }
    return (
        auth? <Outlet/> : <Navigate to="/login"/>
      // auth.token ? <Outlet/> : <Navigate to="/login"/>
    )

}
export default PrivateRoutes;
