import Navbar from '../components/Navbar';
import { Outlet } from "react-router-dom";
import { Box } from '@mui/material';


const Applayout = () => {
   
    return (
    <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box component="main" sx={{ flexGrow: 1 }}>
            <Outlet/>
        </Box>
    </Box>
    )
}

export default Applayout;