import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { alpha, Box, Button, Grid, Stack, styled } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import StyledSearch from '../components/Searchbar';
import { ComputersList } from '../Mocks/Computers';
import {Link as RouterLink} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useEffect } from 'react';
import { fetchDevices } from '../redux/reducers/Computer';
import { Computer } from '../types/deviceinterfaces';



      
      function SerialNumberList() {
        const dispatch = useAppDispatch()
        const CompList = useAppSelector(state => state.comp.alldevices);
          useEffect(() => {
            dispatch(fetchDevices())
          }, []);

        return (
        <><>
            <Toolbar disableGutters>
              <Typography variant='h1' sx={{ 
                paddingTop: 9, 
                paddingBottom: 9, 
                flexGrow: 1, 
                display: { xs: 'none', sm: 'block' } }}>
                    Numer seryjny
                    </Typography>
              {/* <StyledSearch /> */}
            </Toolbar>

            <TableContainer sx={{ paddingRight: '5%' }}>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Nr seryjny</TableCell>
                    <TableCell align="left">Producent / Model</TableCell>
                    <TableCell align="left">Typ</TableCell>
                    <TableCell align="left">Właściciel</TableCell>
                    <TableCell align="left">Szczegóły</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {CompList && CompList.map((row:Computer) => (
                    <TableRow
                      key={row.model}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& .MuiTableCell-root': { color: '#3B3E45', fontWeight: '400' }, }}>
                      <TableCell align="left">{row.serial_number}</TableCell>
                      <TableCell align="left">{row.producer}<br/>{row.model}</TableCell>
                      <TableCell align="left">{row.type}</TableCell>
                      <TableCell align="left">{row.owner}</TableCell>
                      <TableCell align="left"><Button variant="outlined" size="small" color="secondary" component={RouterLink} to={`/computer/${row.serial_number}`}>Szczegóły</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Divider />
            </TableContainer>
            </>
            </>            
        );
      }

export default SerialNumberList;