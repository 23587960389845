import {createSlice} from "@reduxjs/toolkit"
import { Computer } from "../../types/deviceinterfaces"
import { getToken } from "../../common/helpers"
import { Assign } from "../../types/assign"
import { format } from "date-fns"
import { DeviceHistory } from "../../types/history"

interface DeviceState {
    computers: Computer[] | null
    compadd: Computer[] | null,
    devices: Computer[] | null,
    devicedata: Computer | null,
    editdevice: Computer[] | null
    assigndevice: Assign[] | null
    devicehistory: DeviceHistory[] | null,
    userhistory: DeviceHistory[] | null,
    alldevices: Computer[] | null,
}

const initialState: DeviceState = {
    computers: null,
    compadd: null,
    devices: null,
    devicedata: null,
    editdevice: null,
    assigndevice: null,
    devicehistory: null,
    userhistory: null,
    alldevices: null,
}

export const comp = createSlice({
  name: "comp",
  initialState,
  reducers: {
    addComputer: (state, action) => {
        state.compadd = action.payload
    },
    saveDevices: (state, action) => {
        state.computers = action.payload
      },
    displayAccessories: (state, action) => {
        state.devices = action.payload
      },
    currentDevice: (state, action) => {
        state.devicedata = action.payload
      },
    changeDevice: (state, action) => {
        state.editdevice = action.payload
    },
    assignDeviceToWorker: (state, action) => {
        state.assigndevice = action.payload
    },
    setDeviceHistory: (state, action) => {
        state.devicehistory = action.payload
    },
    setUserHistory: (state, action) => {
        state.userhistory = action.payload
    },
    saveAllDevices: (state, action) => {
        state.alldevices = action.payload
    },
  },
})

export const { saveDevices, setDeviceHistory, setUserHistory, saveAllDevices } = comp.actions
export const fetchComputers = () => {
    return (dispatch: any) => {
        return fetch(`/api/devices/?device_status=pc`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((computers) => {
                                dispatch(saveDevices(computers));
                                return computers;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};

export const fetchDevices = () => {
    return (dispatch: any) => {
        return fetch(`/api/devices/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((computers) => {
                                dispatch(saveAllDevices(computers));
                                return computers;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};
export const { currentDevice } = comp.actions
export const fetchCurrentDevice = (serial_number: string) => {
    return (dispatch: any) => {
        return fetch(`/api/devices/${serial_number}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((devices) => {
                                dispatch(currentDevice(devices));
                                return devices;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};

export const { addComputer } = comp.actions
export const addComputerToGroup = (payload:Computer) => {
    return (dispatch: any) => {
        return fetch(`/api/devices/`, {
            method: "POST",
            body:
                JSON.stringify({
                    ...payload
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {

                    dispatch(addComputer(comp))
                } else if (res.status === 409) {
                    res.text().then(text => {
                            if (text.includes("Device is already in group")) {
                            }
                        }
                    )
                }
                else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const { assignDeviceToWorker } = comp.actions
export const editDevice = (payload: Computer, serial_number: string) => {
    return (dispatch: any) => {
        return fetch(`/api/devices/${serial_number}`, {
            method: "PATCH",
            body:
                JSON.stringify({
                   ...payload
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchCurrentDevice(serial_number));
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const { changeDevice } = comp.actions
export const assignDevice = (payload: Assign, serial_number: string) => {
    return (dispatch: any) => {
        return fetch(`/api/devices/assign-reassign/${serial_number}`, {
            method: "PATCH",
            body:
                JSON.stringify({
                   ...payload,
                   start_date: format(new Date(payload.start_date),'yyyy-MM-dd')
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchCurrentDevice(serial_number));
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const deleteDevice = (deleted_note:string, serial_number: string) => {
    return () => {
        return fetch(`/api/devices/${serial_number}`, {
            method: "DELETE",
            body:
            JSON.stringify({
              deleted_note
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const { displayAccessories } = comp.actions
export const fetchAccessories = () => {
    return (dispatch: any) => {
        return fetch(`/api/devices/?device_status=accessories`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((devices) => {
                                dispatch(displayAccessories(devices));
                                return devices;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};


export const fetchDeviceHistory = (serial_number: string) => {
    return (dispatch: any) => {
        return fetch(`/api/history/?serial_number=${serial_number}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((history) => {
                                dispatch(setDeviceHistory(history));
                                return history;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};
export const fetchUserHistory = (id_worker: number) => {
    return (dispatch: any) => {
        return fetch(`/api/history/?id_worker=${id_worker}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((history) => {
                                dispatch(setUserHistory(history));
                                return history;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};


export default comp.reducer
