import Typography from '@mui/material/Typography';
import { Box, Button, Container, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Formik, Form,} from "formik";
import { useAppDispatch } from '../redux/hooks';
import { postLogin } from '../redux/reducers/Login';
import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoginType } from '../types/login';
import { useNavigate } from 'react-router-dom';


const initialFormState = {
    username: "",
    password: "",
    showPassword: false,
}


function Login() {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const dispatch = useAppDispatch();
    const navigate=useNavigate()
    const login=()=>{
      navigate('/users')
    }

    return (

        <Container maxWidth="sm"
        sx={{
            marginTop: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "calc(100vh - 200px)",
            width: "600px",
          }}
        >
        <Box sx={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            
            borderRadius: "6px",
            }}>
        <><Toolbar disableGutters sx={{
            alignItems: 'center',
            paddingTop: 5,
        }}>

            <Typography variant='h1' sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' },
                textAlign: "center"
            }}>
                <div>Logowanie
                <Typography variant='subtitle1'>DevopsGear</Typography></div>
            </Typography>

        </Toolbar>
        <div style={{ padding: 45}}>
                <Formik
                    initialValues={initialFormState}
                    onSubmit={async(values) => 
                        {await dispatch(postLogin(values.username,values.password))
                        navigate('/users')}}

                   // onSubmit ={(values) => console.log(values)}
                >
                    {({ values, handleSubmit,handleChange, submitForm }) => (<Form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid xs={12} item>
                                <TextField
                                    id="username"
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    label="Login"
                                    variant="outlined"
                                    fullWidth 
                                    sx={{backgroundColor: "rgba(0, 0, 0, 0.01)"}}
                                    />
                            </Grid>
                            <Grid xs={12} item>
                                 <TextField
                                    id="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    label="Hasło"
                                    variant="outlined"
                                    fullWidth
                                    sx={{backgroundColor: "rgba(0, 0, 0, 0.01)"}}
                                    type={passwordShown ? 'text' : 'password'}
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {passwordShown ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        )}}
                                     />  
                            </Grid>
                            <Grid item>
                                <Button 
                                type="submit" 
                                onClick={submitForm} 
                                variant="outlined" 
                                size="medium" 
                                color="secondary" 
                                sx={{ width: "150px",backgroundColor: "white"}} 
                                style={{ fontWeight: '400' }}>
                                Zaloguj
                                </Button>
                            </Grid>
                            <Box width="100%" />
                        </Grid>
                    </Form>)}
                    
                </Formik>
                
            </div></>
         </Box>
     </Container>
    );
}


export default Login;

function handleSubmit() {
        throw new Error('Function not implemented.');
    }
