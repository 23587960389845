import {createSlice} from "@reduxjs/toolkit"
import { Demands } from "../../types/demand"
import { getToken } from "../../common/helpers"

interface DemandState {
    demandlist: Demands[] | null
    demandadd: Demands[] | null,
    demanddata: Demands | null,
    demandedit: Demands [] | null
}

const initialState: DemandState = {
    demandlist: null,
    demandadd: null,
    demanddata: null,
    demandedit: null,
}

export const dem = createSlice({
  name: "dem",
  initialState,
  reducers: {
    addDemand: (state, action) => {
        state.demandadd = action.payload
    },
    saveDemand: (state, action) => {
        state.demandlist = action.payload
      },
    currentDemand: (state, action) => {
        state.demanddata = action.payload
      },
    changeDemand: (state, action) => {
        state.demandedit = action.payload
    },
  },
})

export const { saveDemand } = dem.actions
export const fetchDemandList = () => {
    return (dispatch: any) => {
        return fetch(`/api/demand/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((demandlist) => {
                                dispatch(saveDemand(demandlist));
                                return demandlist;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};
export const { currentDemand } = dem.actions
export const fetchCurrentDemand = (id: number) => {
    return (dispatch: any) => {
        return fetch(`/api/demand/${id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res
                        .json()
                        .then((demandlist) => {
                                dispatch(currentDemand(demandlist));
                                return demandlist;
                            }
                        );
                } else {
                    return res.json().then((error) => {
                        console.log(error)
                        return false
                    });
                }
            });
    };
};

export const { addDemand } = dem.actions
export const addDemandToList = (payload:Demands) => {
    return (dispatch: any) => {
        return fetch(`/api/demand/`, {
            method: "POST",
            body:
                JSON.stringify({
                    ...payload
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {

                    dispatch(addDemand(dem))
                } else if (res.status === 409) {
                    res.text().then(text => {
                            if (text.includes("Demand is already in group")) {
                            }
                        }
                    )
                }
                else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const { changeDemand } = dem.actions
export const editDemand = (payload: Demands, id: number) => {
    return (dispatch: any) => {
        return fetch(`/api/demand/${id}`, {
            method: "PATCH",
            body:
                JSON.stringify({
                   ...payload
                }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    dispatch(fetchCurrentDemand(id));
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};

export const deleteDemand = (id: number) => {
    return () => {
        return fetch(`/api/demand/${id}`, {
            method: "DELETE",
            body:
            JSON.stringify({
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                } else {
                    return res.json().then(() => {
                        return false
                    });
                }
            });
    };
};



export default dem.reducer
