import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect } from 'react';
import { deleteService, fetchServiceList } from '../../redux/reducers/Service';
import { Services } from '../../types/services';

      
      function Service() {

        const dispatch = useAppDispatch()
        const ServiceList = useAppSelector(state => state.serv.servicelist);
          useEffect(() => {
            dispatch(fetchServiceList())
          }, []);

          const navigate = useNavigate()
          const handleDelete = async (serviceId: number) => {
            if(!window.confirm("Czy napewno chcesz usunąć zgłoszenie?")) {
              return false
            }
           await dispatch(deleteService(serviceId))
           window.location.reload();
         }
          
        return (
        <><>
            <Toolbar disableGutters>
              <Typography variant='h1' sx={{ 
                paddingTop: 9, 
                paddingBottom: 9, 
                flexGrow: 1, 
                display: { xs: 'none', sm: 'block' } }}>
                    Serwis
                    </Typography>

            </Toolbar>

            <TableContainer sx={{ paddingRight: '5%' }}>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Numer Seryjny</TableCell>
                    <TableCell align="left">Typ</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Problem</TableCell>
                    <TableCell align="left">Szczegóły</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {ServiceList && ServiceList.map((row:Services) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& .MuiTableCell-root': { color: '#3B3E45', fontWeight: '400' }, }}>
                      <TableCell align="left">{row.serial_number}</TableCell>
                      <TableCell align="left">{row.type}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">{row.problem}</TableCell>
                      <TableCell align="left">
                        <Button variant="outlined" size="small" color="secondary" sx={{mr:1}} component={RouterLink} to={`/editserviceform/${row.id}`}>Edycja</Button>
                        <Button 
                        type="submit" 
                        onClick={() => handleDelete(Number(row.id))} 
                        variant="outlined" 
                        size="small"
                        color="error" 
                        style={{ fontWeight: '400' }}>
                        Usuń
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Divider />
            </TableContainer>
            </>
              <Box
              m={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ paddingRight: '5%' }}>
              <Button variant="text" color="secondary" startIcon={<AddIcon />} sx={{ height: 40 }} component={RouterLink} to={`/serviceform`}>Dodaj zgłoszenie</Button>
            </Box>
            </>            
        );
      }

export default Service;