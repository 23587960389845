import jwt_decode, { JwtPayload } from 'jwt-decode';

export const setToken = (token: string) => {localStorage.setItem("gear-token", token)};
export const getToken = () => {return localStorage.getItem("gear-token")};
export const deleteToken = () => {localStorage.removeItem("gear-token")}

export const isTokenValid = () => {
  const token = getToken();
  if (token) {
    const decodedToken: any = jwt_decode<JwtPayload>(token);
    return decodedToken.exp * 1000 >= new Date().getTime();
  }
  return false
}


