import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useNavigate } from 'react-router-dom';
import { DevicesOtherTwoTone } from '@mui/icons-material';
import { deleteToken } from '../common/helpers';


const drawerWidth = 240;

export default function PermanentDrawerLeft() {

  const navigate = useNavigate();

  const handleLogout = () => {
    deleteToken()
    navigate('/login', {replace: true})
  }

  return (
    <Box sx={{ display: 'flex', }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`,}}
      >

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor:'#F3F3F3',
            border: 'none',
            pt: '25px',
          },
        }}
        variant="permanent"
        anchor="left"
      >


        <List
          sx={{
          flex: '1',
        }}>
             <ListItem>
                  <ListItemIcon sx={{minWidth: '0px', pl: '22px', fontSize: 'small'}}>
                  {<DevicesOtherTwoTone />}
                </ListItemIcon>
                <ListItemText primary="DevopsGear"  primaryTypographyProps={{
                display:'block',
                padding: '15px 3px',
                width: '100%',
                fontWeight: '600',
              }}>
                </ListItemText>
            </ListItem>

            {/* <ListItem disablePadding >
            <ListItemButton sx={{padding:'0px'}}>

                <NavLink to="/" className={({isActive}) =>isActive?'link active':'link'} style={{display:'block', padding: '15px 39px', width: '100%'}}>
                 <ListItemIcon sx={{minWidth: '0px', pl: '0px', fontSize: 'small', verticalAlign: 'text-bottom'}}>
                  {<DevicesOtherTwoTone />}
                </ListItemIcon>
                  <a>  DevopsGear</a>
                 </NavLink>
              </ListItemButton>
            </ListItem> */}

            <ListItem disablePadding >
            <ListItemButton sx={{padding:'0px'}}>
                <NavLink to="/users" className={({isActive}) =>isActive?'link active':'link'} style={{display:'block', padding: '15px 39px', width: '100%'}}>
                    Użytkownicy
                 </NavLink>
              </ListItemButton>
            </ListItem>



            <ListItem disablePadding>
               <ListItemButton sx={{padding:'0px'}}>
                <NavLink to="/computers" className={({isActive}) =>isActive?'link active':'link'} style={{display:'block', padding: '15px 39px', width: '100%'}}>
                    Komputery
                   </NavLink>

              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
            <ListItemButton sx={{padding:'0px'}}>
                <NavLink to="/accessories" className={({isActive}) =>isActive?'link active':'link'} style={{display:'block', padding: '15px 39px', width: '100%'}}>
                    Akcesoria
                   </NavLink>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
            <ListItemButton sx={{padding:'0px'}}>
                <NavLink to="/demand" className={({isActive}) =>isActive?'link active':'link'} style={{display:'block', padding: '15px 39px', width: '100%'}}>
                    Zapotrzebowanie
                   </NavLink>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
            <ListItemButton sx={{padding:'0px'}}>
                <NavLink to="/service" className={({isActive}) =>isActive?'link active':'link'} style={{display:'block', padding: '15px 39px', width: '100%'}}>
                    Serwis
                   </NavLink>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
            <ListItemButton sx={{padding:'0px'}}>
                <NavLink to="/serialnumber" className={({isActive}) =>isActive?'link active':'link'} style={{display:'block', padding: '15px 39px', width: '100%'}}>
                    Numery seryjne
                   </NavLink>
              </ListItemButton>
            </ListItem>

        </List>

        <Box
          sx={{
            padding: '25px 39px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
          onClick={() => handleLogout()}
        >
         Wyloguj się
        </Box>

      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 1 }}
      >
        <Toolbar />
      </Box>
    </Box>
  );
}
