import Typography from '@mui/material/Typography';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form} from "formik";
import { useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { itemSchema } from '../../Validation/ItemSchema';
import { addDemandToList, editDemand, fetchCurrentDemand } from '../../redux/reducers/Demand';
import { fetchUsers } from '../../redux/reducers/User';
import { DemandSchema } from '../../Validation/DemandSchema';

const type = [
    { value: 'pc', label: 'Komputer' },
    { value: 'monitor',  label: 'Monitor'},
    { value: 'headphones',  label: 'Headset' },
    { value: 'keyboard',  label: 'Klawiatura' },
    { value: 'mouse',  label: 'Mysz'},
]
const status = [
    { value: 'verification', label: 'W trakcie weryfikacji' },
    { value: 'completed',  label: 'Zakończone'},
    { value: 'rejected',  label: 'Odrzucone' },
]

function DemandForm() {

const {id} = useParams();
const dispatch = useAppDispatch();
const dem = useAppSelector(state => state.dem.demanddata);
const navigate = useNavigate()

useEffect(() => {
    if (!dem) {
        dispatch(fetchCurrentDemand(Number(id)))
    }
}, []);

const UsersList = useAppSelector(state => state.user.list);
useEffect(() => {
    if(!UsersList?.length){
        dispatch(fetchUsers())
    }
    }, [])

   
const mappedUsersList = UsersList ? UsersList.map((user) => { return { label: user.name + ' ' + user.second_name, value: user.id } } ) : []

const initialFormState = {
    id_worker: "",
    name: dem?.name || "",
    type: dem?.type || "",
    status: dem?.status || "",
    value: dem?.value || "",
    description: dem?.description || "",
}

    return (
        <><Toolbar disableGutters sx={{
            paddingTop: 9,
            paddingBottom: 6,
            alignItems: 'flex-start'
        }}>

            <Typography variant='h1' sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' }
            }}>
                <div>Edycja zapotrzebowania
                    {/* <Typography variant='subtitle1'>Edycja / Dodawanie</Typography> */}
                    </div>
            </Typography>
        </Toolbar>

            <div style={{ width: "700px" }}>
                <Formik
                    initialValues={initialFormState}
                    validationSchema={DemandSchema}
                    enableReinitialize
                    //onSubmit={(values) => dispatch(editDemand(values, Number(id)))}
                    onSubmit={async(values) => 
                        {await dispatch(editDemand(values, Number(id)))
                        navigate('/demand')}}
                >
                    {({ handleChange, values, setFieldValue, handleSubmit, touched, errors, handleBlur }) => (<Form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justifyContent="center">
                        <Grid xs={12} item>
                        <Autocomplete
                                disablePortal
                                id="id_worker"
                                popupIcon={<ExpandMore />}
                                options={mappedUsersList}
                                onChange={(e, value) => setFieldValue("id_worker", value?.value)}
                                renderInput={(params) => 
                                <TextField {...params} 
                                label="Pracownik"
                                required 
                                error={touched.id_worker && !!errors.id_worker}
                                helperText={touched.id_worker ? errors.id_worker : ""}
                                onBlur={handleBlur}
                                />}
                                /> 
                            </Grid> 
                        <Grid xs={6} item>
                        <TextField
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    label="Nazwa sprzętu"
                                    variant="outlined"
                                    fullWidth 
                                    required
                                    />
                            </Grid>
                            <Grid xs={6} item>
                            <Autocomplete
                                disablePortal
                                id="type"
                                popupIcon={<ExpandMore />}
                                options={type}
                                onChange={(e, value) => setFieldValue("type", value?.value)}
                                renderInput={(params) => 
                                <TextField {...params} 
                                label="Typ"
                                required 
                                error={touched.type && !!errors.type}
                                helperText={touched.type ? errors.type : ""}
                                onBlur={handleBlur}
                                />}
                                /> 
                             </Grid>
                             <Grid xs={6} item>
                             <Autocomplete
                                disablePortal
                                id="status"
                                popupIcon={<ExpandMore />}
                                options={status}
                                onChange={(e, value) => setFieldValue("status", value?.value)}
                                renderInput={(params) => 
                                <TextField {...params} 
                                label="Status"
                                required 
                                error={touched.status && !!errors.status}
                                helperText={touched.status ? errors.status : ""}
                                onBlur={handleBlur}
                                />}
                                /> 
                             </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    id="value"
                                    name="value"
                                    value={values.value}
                                    onChange={handleChange}
                                    label="Wartość sprzętu"
                                    variant="outlined"
                                    fullWidth 
                                    error={touched.value && !!errors.value}
                                    helperText={touched.value ? errors.value : ""}
                                    onBlur={handleBlur}
                                    />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    id="description"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    label="Komentarz"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth />
                            </Grid>


                            <Grid item>
                                <Button type="submit" variant="outlined" size="medium" color="secondary" sx={{ width: "150px" }} style={{ fontWeight: '400' }}>Akceptuj</Button>
                            </Grid>
                            <Box width="100%" />
                            <Grid item>
                                <Button variant="outlined" size="medium" color="info" sx={{ width: "150px" }} style={{ fontWeight: '400' }} component={RouterLink} to="/demand">Wróć</Button>
                            </Grid>
                        </Grid>
                    </Form>)}
                </Formik>
            </div>

        </>

    );
}


export default DemandForm;